var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.operationGroups.length)?_c('div',{staticClass:"animated fadeIn"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.bopShort)+" ")])]),_c('div',[_c('b-badge',{attrs:{"variant":_vm.bop.bopStatusKey}},[_vm._v(" "+_vm._s(_vm.bopStatus)+" "+_vm._s(_vm.canBeEditedAfterRealised ? _vm.$t("bop.beingEditedAfterFinished").toString() : "")+" ")])],1)]),_c('h4',{staticClass:"step-sub-title"},[_vm._v(" "+_vm._s(_vm.$t("bopSetup.operationPrincipal.title"))+" ")]),(
      Object.keys(_vm.globalCoefficients).length &&
        _vm.selectedBopModel.key !== 'Echafaudage'
    )?_c('b-card',{staticClass:"default mt-2"},[_c('b-row',_vm._l((_vm.globalCoefficients),function(coeff){return _c('b-col',{key:coeff.id},[_c('b-form-group',{key:("" + (coeff.id)),attrs:{"label":coeff.name,"label-for":("field-" + (coeff.id))}},[_c('b-form-select',{attrs:{"value":_vm.selectedGlobalCoefficients[coeff.coeffKey],"options":coeff.options,"value-field":"key.id","text-field":"value","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.onGlobalCoefficientSelectChange($event, coeff)}}})],1)],1)}),1)],1):_vm._e(),_vm._l((_vm.operationGroups),function(og){return [(!og.canBeDeleted && (!_vm.isEchafaudage || og.operations.length))?_c('div',{key:og.id,staticClass:"mt-3 operation-group-container"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(og.operationFamilyName)+" ")])]),_c('OperationComponent',{key:((og.id) + "_" + (_vm.$i18n.locale) + "_" + (_vm.isEchafaudage ? og.operations.length : '')),attrs:{"fieldCoefficient":_vm.coefficientsPerFamily[og.operationFamilyId],"bopModelId":_vm.bop.bopModelId,"operationType":1,"operationGroup":og,"isEditable":_vm.isEditable,"operationFamilyId":og.operationFamilyId,"columns":_vm.columnsPerFamily[og.operationFamilyId],"operationGroupCoefficientFields":_vm.operationGroupCoefficientFields},on:{"operationChange":_vm.onOperationChange,"totalPointsChanged":_vm.onTotalPointsChanged,"fully-loaded":_vm.onFullyLoaded}})],1):_vm._e()]}),(
      Object.keys(_vm.globalCoefficients).length &&
        _vm.selectedBopModel &&
        _vm.selectedBopModel.key === 'Echafaudage'
    )?_c('b-card',{staticClass:"default mt-2"},[_c('b-row',_vm._l((_vm.globalCoefficients),function(coeff){return _c('b-col',{key:coeff.id},[_c('b-form-group',{key:("" + (coeff.id)),attrs:{"label":coeff.name,"label-for":("field-" + (coeff.id))}},[_c('b-form-select',{attrs:{"value":_vm.selectedGlobalCoefficients[coeff.coeffKey],"options":coeff.options,"value-field":"key.id","text-field":"value","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.onGlobalCoefficientSelectChange($event, coeff)}}})],1)],1)}),1)],1):_vm._e(),(_vm.operationGroups.length > 1)?_c('b-card',{staticClass:"primary mt-4"},[_c('b-row',{staticClass:"pl-sm-2 pr-sm-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"pt-2",attrs:{"label":_vm.$t('bopSetup.operationPrincipal.totalPoints'),"label-cols-sm":"6","label-cols-lg":"9","content-cols-sm":"","content-cols-lg":"3"}},[_c('b-input',{attrs:{"value":_vm.allTotalPoints,"disabled":""}})],1)],1)],1)],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }